import './SubscriptionsPage.scss'

const emailInput = document.getElementById('email');
const promoCodeInput = document.getElementById('promo-code');
const submitButton = document.getElementById('submit-button');
const emailError = document.getElementById('email-error');
const subscriptionForm = document.getElementById('subscription-form');

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const base_url = 'https://user-backend-staging.afterhourscasino.com/api/v1'
const record_user_api = `${ base_url }/user/record-user-detail`

function isValidEmail(email) {
  return emailPattern.test(email);
}

function handleEmailInput() {
  const emailValue = emailInput.value.trim();

  emailError.textContent = '';

  if (!emailValue) {
    emailError.textContent = 'Email is required';
    return;
  }

  if (!isValidEmail(emailValue)) {
    emailError.textContent = 'Enter correct Email address.';
    return;
  }
}

emailInput.addEventListener('input',handleEmailInput);

subscriptionForm.addEventListener('submit',(event) => {
  event.preventDefault();

  handleEmailInput();

  const emailValue = emailInput.value.trim();
  const promoCodeValue = promoCodeInput.value.trim();

  submitForm(emailValue,promoCodeValue);
});

function resetFormFields() {
  emailInput.value = '';
  promoCodeInput.value = '';
}

function showThankYouMessage() {
  subscriptionForm.style.display = 'none';

  const container = document.getElementById('thank-you-container')
  const thankYouMessage = document.createElement('h2');
  thankYouMessage.textContent = 'Thank you for your submission!';
  thankYouMessage.classList.add('thank-you-message');
  thankYouMessage.classList.add('fade-in');

  container.appendChild(thankYouMessage)

  resetFormFields();

  setTimeout(() => {
    thankYouMessage.remove();
    subscriptionForm.style.display = 'block';
  },5000);
}

function submitForm(email,promoCode) {

  const emailValue = emailInput.value.trim();
  if (!emailValue) {
    emailError.textContent = 'Email is required';
    return;
  }
  if (emailValue && !isValidEmail(emailValue)) {
    emailError.textContent = 'Enter correct Email address.';
    return;
  }

  fetch(record_user_api,{
    method: 'POST',
    // mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
      'Cross-Origin-Resource-Policy': 'same-site'
    },
    body: JSON.stringify({
      email,
      promoCode,
    }),
  })
    .then((response) => {
      if (response.ok) {
        resetFormFields();
        showThankYouMessage()
        // submitButton.textContent = 'Email Submitted';
        // setTimeout(() => {
        //     submitButton.textContent = 'Submit'; 
        // }, 3000);
        setTimeout(() => {
          window.open("https://staging.afterhourscasino.com","_self")
        },2000);
      } else {
        console.error('Form submission failed');
      }
    })
    .catch((error) => {
      console.error('Error:',error);
    });
}
